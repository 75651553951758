<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-dialog v-model="dialog" width="800" persistent>
      <v-card class="rounded-lg pl-10 pr-3 pb-6" width="800">
        <v-card-title>
          <span style="color: DarkCyan" class="text-h5"
            >Adicionar Fontes RSS</span
          >

          <v-spacer></v-spacer>

          <v-btn color="white" text :to="{ name: 'Rss' }">
            <v-icon color="#5E6278"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-divider class="mb-5"></v-divider>

        <v-row>
          <v-col cols="8">
            <BaseInputValidator
              type="textField"
              label="Nome: *"
              clearable
              :rules="{ required: true }"
              rounded
              filled
              fieldLayout="filled"
              v-model="form.name"
              dense
              background-color="#F5F8FA"
            ></BaseInputValidator>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8">
            <BaseInputValidator
              type="textField"
              label="URL: *"
              clearable
              :rules="{ required: true }"
              rounded
              filled
              fieldLayout="filled"
              v-model="form.url"
              dense
              background-color="#F5F8FA"
            ></BaseInputValidator>
          </v-col>
        </v-row>

        <v-divider class="pb-2"></v-divider>

        <v-row class="mt-2">
          <v-spacer></v-spacer>
          <v-col class="mr-4" cols="2">
            <v-btn
              :disabled="invalid"
              x-large
              class="rounded white--text"
              color="primary"
              @click="verifyTypeForm"
            >
              Aplicar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>

<script>
import BaseInputValidator from "../../components/UI/BaseInputValidator";
import { ValidationObserver } from "vee-validate";
import ContentService from "../contents/service";

export default {
  name: "CreateRss",
  data() {
    return {
      dialog: true,
      form: {
        name: "",
        url: "",
        type: "RSS",
      },
    };
  },
  methods: {
    async sendRssInfo() {
      try {
        const rss = await ContentService.createContent(this.form);
        return rss;
      } catch (error) {
        console.error(error);
      }
    },
    async verifyTypeForm() {
      if (this.typeForm === "EditRss") {
        const rss = await this.updateRss();
        this.$router.push({ name: "Rss" });
        this.$emit("created", { rss: rss, typeForm: this.typeForm });
      } else {
        const rss = await this.sendRssInfo();
        this.$router.push({ name: "Rss" });
        this.$emit("created", { rss: rss, typeForm: this.typeForm });
      }
    },
    async updateRss() {
      try {
        const rss = await ContentService.updateContent(
          this.form,
          this.$route.params.id
        );
        return rss;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    this.typeForm = this.$route.name;
    if (this.typeForm === "EditRss") {
      const rss = await ContentService.getContentById(this.$route.params.id);
      this.form = rss.data;
    }
  },
  components: {
    BaseInputValidator,
    ValidationObserver,
    // RssApprove,
  },
};
</script>
